exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-flight-jsx": () => import("./../../../src/pages/flight.jsx" /* webpackChunkName: "component---src-pages-flight-jsx" */),
  "component---src-pages-health-center-jsx": () => import("./../../../src/pages/health-center.jsx" /* webpackChunkName: "component---src-pages-health-center-jsx" */),
  "component---src-pages-home-jsx": () => import("./../../../src/pages/home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-hotel-jsx": () => import("./../../../src/pages/hotel.jsx" /* webpackChunkName: "component---src-pages-hotel-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-journey-jsx": () => import("./../../../src/pages/journey.jsx" /* webpackChunkName: "component---src-pages-journey-jsx" */),
  "component---src-pages-meals-jsx": () => import("./../../../src/pages/meals.jsx" /* webpackChunkName: "component---src-pages-meals-jsx" */),
  "component---src-pages-transportation-jsx": () => import("./../../../src/pages/transportation.jsx" /* webpackChunkName: "component---src-pages-transportation-jsx" */),
  "component---src-pages-trip-jsx": () => import("./../../../src/pages/trip.jsx" /* webpackChunkName: "component---src-pages-trip-jsx" */)
}

